import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useTranslation} from "../lang/TranslationContext";
import Header from "../blocks/Header";

const Tasks = ({tg}) => {
    const { t } = useTranslation();

    const [tasks, setTasks] = useState([]);
    const [tasksHistory, setTasksHistory] = useState([]);
    const [tasksType, setTasksType] = useState(1);

    const fetchTasks = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}getTasks.php`, 
              {
                headers: {
                  'Content-Type': 'application/json'
              },
            }
            )
            .then(response => {
                setTasks(response.data.tasks);
            }).catch(error => {
              console.log('Error fetching data:', error);
            });
    };

    const fetchTasksHistory = async () => {
        const initData = tg && tg.initData ? tg.initData : null;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}getTasksHistory.php`, 
              {
                headers: {
                  'Authorization': initData,
                  'Content-Type': 'application/json'
              },
            }
            )
            .then(response => {
                setTasksHistory(response.data.history);
            }).catch(error => {
              console.log('Error fetching data:', error);
            });
    };

    const checkTask = async (task_id) => {
        try {
        const initData = tg && tg.initData ? tg.initData : null;
                const response = await fetch(`${process.env.REACT_APP_API_URL}checkTask.php`, {
                        method: 'POST',
                        headers: {
                                'Authorization': initData || '',
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({'task_id': task_id}),
                });

                const result = await response.json();
                if (result.status === 'success') {
                    fetchTasksHistory()
                } else {
                    alert(result.message)
                }
        } catch (error) {
            console.log(error)
        }
};
  

    const doTask = async (task) => {
        window.open(task.link, '_blank');
        setTimeout(() => {
            checkTask(task.id);
        }, 5000); 
    }
    
  
    useEffect(() => {
        fetchTasksHistory();
        fetchTasks();
    }, [tg]);

    // const tasks = [
    //     // {id: 1, name: 'Subscribe to the cnanel', link: 'https://t.me/tonext', amount: 10},
        
    //     {id: 1,  name: 'Join Channel', link: 'https://t.me/+uSzG_G-bTFo1YmEy', title: "Crypto Whale", amount: 10},
    // ];

    return (
        <>
            <main>
                <section class="referral-section">
                <div class="container">
                    <div className="referral-section__box">
                    <Header tg={tg}/>
                        <h1 className="title">
                            {t("New missions")}
                        </h1>
                        <div className="tasks-tabs" data-tabs-parrent="">
                            <ul className="tasks-nav">
                                <li className="tasks-nav__item">
                                    <button
                                        className={`tasks-nav__btn ${tasksType === 1 ? 'active' : ''}`}
                                        type="button"
                                        data-tab="1"
                                        onClick={() => setTasksType(1)}
                                    >
                                        Our tasks
                                    </button>
                                </li>
                            </ul>
                            <ul className="tasks-content">
                                
                                <li className={`asks-content__item active`} data-tab-content="2">
                                    <ul className="task-list">
                                        {tasks && tasks.map((task) => (

                                            <li className="task-list__item">
                                                <div className="main-info main-info--mode">

                                                    {tasksHistory && (tasksHistory.includes(Number(task.id)) || tasksHistory.includes(String(task.id))) ? (
                                                        <>
                                                            <div className="main-info__col">
                                                            <span className="main-info__image">
                                                              <img width="42" height="38" src="img/index/tg.png" alt="image"/>
                                                            </span>
                                                                <span
                                                                    className="main-info__text">{t(task.name)}</span>
                                                                    <span style={{fontSize: "10px"}}>{task.title}</span>
                                                            </div>
                                                            <div
                                                                className="main-info__col main-info__col--mode active">
                                                                <span className="main-info__icon">
                                                                  <img width="20" height="20" src="img/sprite/check.svg"
                                                                       alt="image"/>
                                                                </span>
                                                                <span className="main-info__text">Complete</span>
                                                            </div>
                                                        </>

                                                    ) : (
                                                        <>
                                                            <div className="main-info__col" onClick={() => {
                                                                doTask(task)
                                                            }}>
                                                                    <span className="main-info__image">
                                                                      <img width="42" height="38" src="img/index/tg.png" alt="image"/>
                                                                    </span>
                                                                <span
                                                                    className="main-info__text">{t(task.name)}</span>
                                                                    <span style={{fontSize: "10px"}}>{task.title}</span>
                                                            </div>
                                                            <div className="main-info__col main-info__col--mode"
                                                                 onClick={() => {
                                                                     doTask(task)
                                                                 }}>
                                                                <span className="main-info__icon">
                                                                  <svg width="24" height="24">
                                                                    <use href="img/sprite/sprite.svg#eco"></use>
                                                                  </svg>
                                                                </span>
                                                                <span
                                                                    className="main-info__text">{task.amount} th/s</span>
                                                            </div>
                                                        </>
                                                    )}


                                                </div>
                                            </li>))}
                                    </ul>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                </section>
            </main>

        </>
    );
};

export default Tasks;
